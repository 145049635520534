import React from "react";
import styled from 'styled-components'
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { ListViewItem, BackButton } from '../../components/common';
import socialIcons from '../../images/community/social-icons/icons.js';


const PageWrapper = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  min-height:100vh;
`

const StoryListWrapper = styled.div`
  padding: 5% 5% 5% 5%;
`

const VoiceProfileWrapper = styled.div`
  padding: 0% 5% 0% 5%;
`

const NameAvatarWrapper = styled.div`
  display:flex;
  position:relative;
  z-index:10;
`

const VoiceName = styled.h1`
  margin: 10px 0 0 20px; 
  width: 67%;
`

const VoiceAvatarWrapper = styled.div`
  width:33%;
  display:flex;
  justify-content:center;
`

const AvatarImageWrapper = styled.div`
  border-radius:50%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  border:5px solid ${props => props.theme.secondaryColor};
  width: 110px;
  height: 110px;
  overflow: hidden;
  margin-top:-55px;
  display: grid;
  place-items: center;
  background-color: ${props => props.theme.secondaryColor};
  @media (max-width: 374px) {
    width: 90px;
    height: 90px;
    margin-top:-45px;
  }
`

const BannerWrapper = styled.div`
  height:130px;
  background-color:white;
  opacity:0.1;
  overflow:hidden;
  position:relative;
  z-index:5;
`

const AvatarPlaceholder = styled.h2`
  text-align:center;
  font-size:45px;
  margin:0px;
  color:white;
`
const CommunityInfoWrapper = styled.div`
 

`

const BioWrapper = styled.div`
  margin:15px 0;
  text-align:center;
  font-family:'poppins';
  font-size:14px;
`

const SocialIconsWrapper = styled.div`
  display:flex;
  width:100%;
  justify-content:center;
`
const SocialIconWrapper = styled.a`
  display:block;
  background-color:${props => props.theme.secondaryColor};
  height:33px;
  width:33px;
  border-radius: 50%;
  margin: 0 10px;
  display:flex;
  justify-content:center;
  align-items:center;
`

const SocialIcon = styled.img`
  height:60%;
  width:60%;

`


const StoryVoiceTemplate = (props) => {

  return(
    <PageWrapper>
      <BackButton absolute marginTop marginLeft/>
      <BannerWrapper>
        {props.data.allCommunityStory.edges.length > 0 &&
          <GatsbyImage image={props.data.allCommunityStory.edges[0].node.localImage.coverImage.gatsbyImageData} alt={`Voice avatar`}/>
        }
      </BannerWrapper>
      <VoiceProfileWrapper>
        <NameAvatarWrapper>
       
        <VoiceAvatarWrapper>
          <AvatarImageWrapper>
            {props.data.communityVoice.localImage != null ?
              <GatsbyImage image={props.data.communityVoice.localImage.childImageSharp.gatsbyImageData} alt={`Voice avatar`}/>
              :
              <AvatarPlaceholder>{props.data.communityVoice.name.charAt(0)}</AvatarPlaceholder>
            }
          </AvatarImageWrapper>
        </VoiceAvatarWrapper>
        <VoiceName>{props.data.communityVoice.name}</VoiceName>
        </NameAvatarWrapper>
        
      <CommunityInfoWrapper>
        <BioWrapper>{props.data.communityVoice.bio}</BioWrapper>
        <SocialIconsWrapper>
        {props.data.communityVoice.links
          .filter(link => link.link !== "") // filter emtpy ones
          .map((link) =>(
            <SocialIconWrapper href={link.link} target="_blank">
              <SocialIcon src={typeof socialIcons[link.type] != 'undefined' ? socialIcons[link.type] : socialIcons.website}/>
            </SocialIconWrapper>
        ))}
        </SocialIconsWrapper>
       
      </CommunityInfoWrapper>
      </VoiceProfileWrapper>
      <StoryListWrapper>
        {props.data.allCommunityStory.edges.map(edge =>(
          <ListViewItem
            id={edge.node.id}
            slug={edge.node.slug}
            title={edge.node.title}
            sampleAudioRef={edge.node.sampleAudioRef}
            storyCover={edge.node.localImage.coverImage.gatsbyImageData}
            thumbnail={edge.node.localImage.thumbnail.gatsbyImageData}
            duration={edge.node.duration}
            voices={edge.node.voices}
            mainFantasy={edge.node.mainFantasy.en.fantasy}
            spiciness={edge.node.spiciness}
            premiumContent={edge.node.premiumContent}
            audiodesiresOriginal={edge.node.audiodesiresOriginal}
            key={edge.node.id}
          />
        ))}
      </StoryListWrapper>
    </PageWrapper>
  )
};
// this way will inject query into props.data
export const query = graphql`
  query CommunityVoiceAppQuery($voiceId: String!, $storyIds: [String!]){
    communityVoice(id: {eq: $voiceId}) {
      name
      slug
      bio
      links{
        type
        link
      }
      localImage{
       childImageSharp {
          gatsbyImageData( placeholder: BLURRED,width: 120, height: 120, layout: CONSTRAINED)
        }
      }
    }
    allCommunityStory(filter: { id: {in: $storyIds}}) {
      edges {
        node {
          ...sliderItemCommunityInfo
        }
      }
    }
  }
  `;  
  
  export default StoryVoiceTemplate;
