
import instagram from'./instagram.svg'
import onlyfans from'./onlyfans.svg'
import reddit from'./reddit.svg'
import twitter from'./twitter.svg'
import website from'./website.svg'
import patreon from'./patreon.svg'
import niteflirt from'./niteflirt.png'
import youtube from'./youtube.svg'
import linktree from'./linktree.svg'


const Icons = 
{ 
  instagram,
  onlyfans,
  reddit,
  twitter,
  website,
  patreon,
  niteflirt,
  youtube,
  linktree
}


export default Icons;